<template>
  <div class="imgs-show">
    <module-title type="l">样衣展示</module-title>
    <div class="my-swiper-wrapper">
      <swiper class="my-swiper-container" ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img src="../assets/swiper/1.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/2.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/3.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/4.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/5.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/6.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/7.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/8.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/9.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/10.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/11.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/12.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/13.jpg">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/swiper/14.jpg">
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
  components: {
    ModuleTitle,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 7,
        spaceBetween: 16,
        loop: true,
        autoplay: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my-swiper-wrapper {
  padding-top: 47px;
}
.my-swiper-container {
  img { width:100%; height: 182px; object-fit: cover;}
}
</style>