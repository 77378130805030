import Vue from 'vue'
import App from './App.vue'

import { loading } from '@jiaminghi/data-view'
import fullScreenContainer from './components/fullScreenContainer'

import 'swiper/css/swiper.css'
import './styles/style.scss'

Vue.use(loading)
Vue.use(fullScreenContainer)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
