<template>
  <div id="top-header">
    <div class="title"></div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader',
}
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/bg_title.png) no-repeat;
  background-size: 100% 100%;
  .title {
    margin-top: 16px;
    width: 536px;
    height: 41px;
    background: url(../assets/text_title.png) no-repeat;
    background-size: 100% 100%;
  }

}
</style>