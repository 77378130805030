<template>
  <div class="base-area-wrap">
    <module-title type="m">洽谈中合作基地 <small style="font-size: 0.65em">(个)</small></module-title>
    <div class="base-area">
      <div class="item">
        <div class="tit">浙江地区</div>
        <div class="map map1">
          4
        </div>
      </div>
      <div class="item">
        <div class="tit">江西地区</div>
        <div class="map map2">
          2
        </div>
      </div>
      <div class="item">
        <div class="tit">安徽地区</div>
        <div class="map map3">
          3
        </div>
      </div>
      <div class="item">
        <div class="tit">江苏地区</div>
        <div class="map map4">
          2
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle.vue";
export default {
  components: {
    ModuleTitle
  }
}
</script>

<style lang="scss" scoped>
.base-area-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.base-area {
  flex-grow: 1;
  display: flex;
  .item {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tit {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 24px;
  }
  .map {
    margin-top: 24px;
    width: 125px;
    height: 115px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 500;
    color: #F0C025;
    line-height: 60px;
    &.map1 {
      background-image: url(../assets/area_zhejiang.png);
    }
    &.map2 {
      background-image: url(../assets/area_jiangxi.png);
    }
    &.map3 {
      background-image: url(../assets/area_anhui.png);
    }
    &.map4 {
      background-image: url(../assets/area_jiangsu.png);
    }
  }
}

</style>