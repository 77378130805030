import { render, staticRenderFns } from "./ModuleTitle.vue?vue&type=template&id=62009db2&scoped=true&"
import script from "./ModuleTitle.vue?vue&type=script&lang=js&"
export * from "./ModuleTitle.vue?vue&type=script&lang=js&"
import style0 from "./ModuleTitle.vue?vue&type=style&index=0&id=62009db2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62009db2",
  null
  
)

export default component.exports