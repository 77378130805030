<template>
  <div id="data-view">
    <dv-loading v-if="loading">加载中...</dv-loading>
    <dv-full-screen-container v-else class="container">
      <top-header />
      <div class="main-content">
        <div class="block-left">
          <div class="block-left-item block-left-1">
            <block-left-top />
          </div>
          <div class="block-left-item block-left-2">
            <blockLeftCenter />
          </div>
          <div class="block-left-item block-left-3">
            <div class="block-left-3-item block-left-3-1">
              <blockLeftBottom1 />
            </div>
            <div class="block-left-3-item block-left-3-2">
              <blockLeftBottom2 />
            </div>
          </div>
        </div>
        <div class="block-right">
          <div class="block-right-item block-right-1">
            <blockRightTop />
          </div>
          <div class="block-right-item block-right-2">
            <blockRightBottom />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div> 
</template>

<script>
import topHeader from './topHeader.vue'
import blockLeftTop from './blockLeftTop.vue'
import blockLeftCenter from './blockLeftCenter.vue'
import blockLeftBottom1 from './blockLeftBottom1.vue'
import blockLeftBottom2 from './blockLeftBottom2.vue'
import blockRightTop from './blockRightTop.vue'
import blockRightBottom from './blockRightBottom.vue'
export default {
  data() {
    return {
      loading: false,
    }
  },
  components: {
    topHeader,
    blockLeftTop,
    blockLeftCenter,
    blockLeftBottom1,
    blockLeftBottom2,
    blockRightTop,
    blockRightBottom
  }
}
</script>

<style lang="scss" scoped>
#data-view {
  width: 100%;
  height: 100%;
}
#dv-full-screen-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
  background-color: #000;
}
.main-content {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 93px);
  padding: 68px 100px 95px;
  .block-left {
    width: 29.65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .block-left-item {
      width: 100%;
      height: 31.4%;
    }
    .block-left-3 {
      display: flex;
      justify-content: space-between;
      border: none;
    }
    .block-left-3-item {
      width: 47.647%;
      height: 100%;
    }
  }
  .block-right {
    width: 68.95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .block-right-1 {
      width: 100%;
      height: 65.7%;
    }
    .block-right-2 {
      width: 100%;
      height: 31.4%;
    }
  }
}
</style>