<template>
  <div class="capcity-center">
    <img class="img" src="../assets/animation_capcity.gif" alt="产能交易中心">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.capcity-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    height: 100%;
  }
}
</style>