<template>
  <div class="outsource-wrap">
    <module-title type="s">外发样衣管理</module-title>
    <div class="outsource">
      <div class="item">
        <div class="text-box">
          <div class="tit">预约外发企业</div>
          <div class="num">{{n1}} <small>家</small></div>
        </div>
        <div class="icon icon-1"></div>
      </div>
      <div class="item">
        <div class="text-box">
          <div class="tit">外发样衣数量</div>
          <div class="num">{{n2}} <small>件</small></div>
        </div>
        <div class="icon icon-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle.vue";
import { getRandomIntInclusive } from '../util/index';
export default {
  components: {
    ModuleTitle
  },
  data() {
    return {
      n1: getRandomIntInclusive(4, 6),
      n2: getRandomIntInclusive(55, 61)
    }
  }
}
</script>

<style lang="scss" scoped>
.outsource-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .outsource {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 39px;
    padding-right: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .tit {
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: #FFFFFF;
    height: 27px;
    padding-right: 10px;
    background: url(../assets/bg_yellow.png) no-repeat bottom right/69px 21px;
  }
  .num {
    margin-top: 11px;
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
    small {
      font-size: 0.65em;
    }
  }
  .icon {
    width: 57px;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    &.icon-1 {
      background-image: url(../assets/ic_company.png);
    }
    &.icon-2 {
      background-image: url(../assets/ic_clothes.png);
    }
  }
}

</style>