<template>
  <div class="week-companys">
    <module-title type="s">上周到访企业</module-title>
    <div class="list">
      <div class="item">
        <div class="num">{{n1}} <small>家</small></div>
        <div class="tit">累计到访数量</div>
      </div>
      <div class="item">
        <div class="num">{{n2}} <small>家</small></div>
        <div class="tit">单日最高来访企业数量</div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle.vue";
import { getRandomIntInclusive } from '../util/index';
const n2 = getRandomIntInclusive(2, 4);
const n2_4 = n2 * 4;
const n1 = getRandomIntInclusive(n2_4-3, n2_4)
export default {
  components: {
    ModuleTitle
  },
  data() {
    return {
      n1,
      n2
    }
  }
}
</script>

<style lang="scss" scoped>
.week-companys {
  height: 100%;
  display: flex;
  flex-direction: column;
  .list {
    .item {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:last-child {
        margin-top: 50px;
      }
    }
    .num {
      font-size: 30px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1;
      small {
        font-size: 0.5em;
      }
    }
    .tit {
      margin-top: 16px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1;
    }
  }

}

</style>