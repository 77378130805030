<template>
  <div class="sample-clothes-wrap">
    <module-title type="m">本周入库样衣数量</module-title>
    <div class="sample-clothes-table">
      <div class="tr head">
        <div class="th">品类</div>
        <div class="th">数量</div>
      </div>
      <div class="tr">
        <div class="td">羽绒类</div>
        <div class="td">{{n1}}</div>
      </div>
      <div class="tr">
        <div class="td">大衣类</div>
        <div class="td">{{n2}}</div>
      </div>
      <div class="tr">
        <div class="td">基础类</div>
        <div class="td">{{n3}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle.vue";
import { getRandomIntInclusive } from '../util/index';
export default {
  components: {
    ModuleTitle
  },
  data() {
    return {
      n1: getRandomIntInclusive(9, 15),
      n2: getRandomIntInclusive(8, 10),
      n3: getRandomIntInclusive(12, 16)
    }
  }
}
</script>

<style lang="scss" scoped>
.sample-clothes-wrap {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.sample-clothes-table {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .tr {
    height: 45px;
    width: 100%;
    display: flex;
    &:nth-child(even) {
      background: url(../assets/bg_line.png) no-repeat;
      background-size: cover;
    }
    &.head {
      background: #092F5A;
    }
  }
  .td, .th {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
  }
}
</style>